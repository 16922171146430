<template>
  <div class="row">
    <div class="col-6 d-flex flex-column justify-content-between">
      <h2 class="mb-3">Details</h2>

      <div>
        <div class="d-flex details-row">
          <strong class="font-18 me-3"><b>File name:</b></strong>
          <span class="font-16 secondary-text file-name-text">{{ fileName }}</span>
        </div>
        <div class="d-flex details-row">
          <strong class="font-18 me-3"><b>Date uploaded:</b></strong>
          <span class="font-16 secondary-text">{{ dateUploaded }}</span>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="d-flex details-row">
        <strong class="font-18 me-3"><b>Inventory: </b></strong>
        <AppBadge type="light-primary" size="md" :rounded="false">
          {{ inventory }}
        </AppBadge>
      </div>

      <div class="d-flex details-row">
        <strong class="font-18 me-3"><b>Used: </b></strong>
        <AppBadge type="danger" size="md" :rounded="false">{{ used }}</AppBadge>
      </div>

      <div class="d-flex details-row">
        <strong class="font-18 me-3"><b>Free: </b></strong>
        <AppBadge type="light-secondary" size="md" :rounded="false">
          {{ free }}
        </AppBadge>
      </div>

      <div class="d-flex details-row">
        <strong class="font-18 me-3"><b>Last used:</b></strong>
        <span class="font-16 secondary-text">{{ lastUsed }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import AppBadge from "@/components/ui/AppBadge";
export default {
  name: "ProfileSlotDetails",
  components: { AppBadge },
  props: {
    fileName: {
      type: String,
      default: "-"
    },
    dateUploaded: { type: String, required: true },
    inventory: { type: String, required: true },
    used: { type: String, required: true },
    free: { type: String, required: true },
    lastUsed: { type: String, required: true }
  }
};
</script>

<style scoped lang="scss">
.details-row {
  padding: 3px 0;

  strong {
    white-space: nowrap;
    font-weight: 600;
  }

  span {
    font-size: 15px;
  }
  span.file-name-text {
    max-width: calc(100% - 6.8rem);
    overflow-wrap: break-word;
  }
}
</style>
